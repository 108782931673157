import * as React from "react"
import Layout from "../components/layout"
import '../style.css'

const Test = () => {
  return (
    <Layout>
        <div>
            <p>test from Test page</p>
        </div>
    </Layout>
  )
}

export default Test
